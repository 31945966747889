import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';


const Footer = ({ intl }) => {
    const currentYear = new Date().getFullYear();

    return (
        <Segment role="contentinfo" padded id="footer">
            <div className="footerTop">

                <div className='logoWhiteDiv'>
                    <img src='../logo_white.svg' alt='Logo White' className='logoWhite' />
                </div>

                <div>
                    <List className="footerList">
                        <div role="listitem" className="item footerName">
                            <p className="item">
                                <FormattedMessage
                                    id="Footer-Name"
                                    defaultMessage="Zürcher Transporte Langnau GmbH"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id="Footer-Adress"
                                    defaultMessage="Sägestrasse 15"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-Plz-Ort'
                                    defaultMessage="3550, Langnau i.E."
                                />
                            </p>
                        </div>
                    </List>
                </div>

                <div>
                    <List className="footerList">
                        <div role="listitem" className="item">
                            <i className="ri-phone-line"></i>
                            <a className="item" href="tel:+41792394827">
                                <FormattedMessage
                                    id='Footer-Telefon'
                                    defaultMessage="079 239 48 27"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <i className="ri-phone-line"></i>
                            <a className="item" href="tel:+41344021020">
                                <FormattedMessage
                                    id='Footer-Festnetz'
                                    defaultMessage="034 402 10 20"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <i className="ri-mail-line"></i>
                            <a className="item" href="mailto:info@zuercher-transporte.ch">
                                <FormattedMessage
                                    id='Footer-Email'
                                    defaultMessage="info@zuercher-transporte.ch"
                                />
                            </a>
                        </div>
                        <br />
                        <div role="listitem" className="item">
                            <a className="item" href="https://www.facebook.com/p/Z%C3%BCrcher-Transporte-Langnau-GmbH-100075537563030/" target='_blank'>
                                <i className="ri-facebook-circle-fill" style={{ fontSize: '20pt' }}></i>
                            </a>
                            <a className="item" href="https://www.instagram.com/zurchertransporte/" target='_blank'>
                                <i className="ri-instagram-fill" style={{ fontSize: '20pt' }}></i>
                            </a>
                        </div>
                    </List>
                </div>
            </div>
            <hr />
            <div className='footerBottom'>
                <div>
                    <List className="footerList">
                        <div role="listitem" className="item">
                            <a className="item" href="./impressum">
                                <FormattedMessage
                                    id='Footer-Imprint'
                                    defaultMessage="Impressum"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <a className="item" href="./datenschutz">
                                <FormattedMessage
                                    id='Footer-Privacy'
                                    defaultMessage="Datenschutzerklärung"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <i className="ri-copyright-line"></i>
                            {currentYear}&nbsp;
                            <FormattedMessage
                                id='Footer-Copyright'
                                defaultMessage="Zürcher Transporte Langnau GmbH"
                            />
                        </div>
                    </List>
                </div>
            </div>
        </Segment>
    );
};

export default injectIntl(Footer);